.Logo {
  width: auto;
  height: auto;
}
.LogoBox {
  display: flex;
  z-index: 9;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}