body {
  color: rgba(0, 0, 0, 0.87);
}
.BlackColor {
  color: rgba(0, 0, 0, 0.87);
}
.SuccessColor {
  color: #2e7d32;
}
.ErrorColor {
  color: #d32f2f;
}
.GrassGreenColor {
  color: #919d86;
}
.MuiAlert-icon {
  position: relative;
}
.MuiAlert-message {
  padding-right: 80px;
}
.MuiAlert-root {
  align-items: flex-start;
  position: relative;
}
.MuiAlert-action {
  position: absolute;
  right: 20px;
  top: 15px;
}
.CommunityNameText {
  display:inline-block;
}
.ErrorcodeContainer {
    min-height: calc(100vh - 104px);
}
.CountHeadingPopulation {
  white-space: nowrap!important;
}
.CustomSnackbar .MuiAlert-icon svg {
  transform: translate(0%, -50%);
}
.LightgreyColor {
  color: rgba(0, 0, 0, 0.6);
}
.Uppercase {
  text-transform: uppercase;
}
.RadioOption .MuiFormControlLabel-root,
.CheckboxOption .MuiFormControlLabel-root {
  margin-bottom: 8px;
}
.MuiPaper-rounded {
  border-radius: 8px;
}
.MuiChip-deleteIcon path {
  visibility: hidden;
}
.MuiChip-deleteIcon {
  background-image: url(assets/images/CrossIcon.svg);
    background-position: top center;
    background-repeat: no-repeat;
}
.SnackbarHeading {
  margin-bottom: 5px;
}
.MuiAlert-action {
  margin-left: 15px;
}
.MuiAlert-action a {
  font-weight: 700 !important;
  text-transform: uppercase;
}
.PageSpacing {
  padding-top: 45px;
  padding-bottom: 45px;
}
.MuiPaginationItem-root {
  height: 35px;
  width: 35px;
  margin: 0 6px;
}
.PaginationWrapper.BasicPagination {
  display: flex;
  align-items: center;
  justify-content: center;
}
.PaginationWrapper.BasicPagination.TablePagination {
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 10px;
  padding-left: 0px;
  padding-right: 0px;
}
.BasicPagination .MuiPaginationItem-ellipsis {
  align-items: center;
  display: flex;
  justify-content: center;
  color: rgba(0, 0, 0, 0.6) !important;
}
.PaginationNumber {
  margin-right: 15px;
  font-weight: 700;
  color: #212121;
  font-size: 14px;
}
.RowsPerPageLabel {
  font-size: 14px!important;
}
.RowsPerPageSelect {
  margin-left: 8px;
}
.RowsPerPageSelect .MuiSelect-select {
  border: none;
  color: #212121;
  font-weight: 700;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  font-size: 14px;
}
.RowsPerPageSelect .MuiSelect-icon {
  right: 0px;
}
.BasicPagination .MuiPaginationItem-root {
  color: #486877;
  font-weight: 600;
  border-radius: 100%;
}
.BasicPagination .MuiPaginationItem-textPrimary.Mui-selected {
  color: #fff;
}
.MuiPaginationItem-textPrimary {
  background-color: #fff;
}
li:first-child .MuiPaginationItem-textPrimary,
li:last-child .MuiPaginationItem-textPrimary {
  background-color: transparent;
}
.BasicPagination .MuiPaginationItem-icon {
  font-size: 30px;
}
.RoundedPagination .MuiPagination-root .MuiPagination-ul .MuiPaginationItem-page,
.RoundedPagination .MuiPagination-root .MuiPagination-ul .MuiPaginationItem-ellipsis {
  border: 1px solid #e5e6ef;
  background: #fff;
  border-radius: 5px;
}
.RoundedPagination .MuiPagination-root .MuiPagination-ul .MuiPaginationItem-page.Mui-selected {
  border: 1px solid rgba(13, 12, 34, 0.8);
  background: rgba(13, 12, 34, 0.8);
  color: #fff;
}
.RoundedPagination .MuiPagination-root .MuiPagination-ul .MuiPaginationItem-page.Mui-disabled {
  border: 1px solid #e5e6ef;
  background: #f4f6fc;
  color: #c6c7d0;
  opacity: 1;
}
.MuiTooltip-popper .MuiTooltip-tooltip {
  background-color: #486877;
  padding: 8px 12px;
}
.TooltipHeading {
  font-weight: 700;
  margin-bottom: 2px;
}
.TooltipText {
  font-weight: 300;
}
.TooltipHeading,
.TooltipText {
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.8px;
}
.MuiTooltip-arrow {
  color: #486877;
}
.ImgResponsive {
  max-width: 100%;
  height: auto;
}
.MuiDialog-paper {
  background-color: #ffffff;
}
.commonFieldBox {
  margin-bottom: 20px;
}
.loginButton {
  width: 100% !important;
  display: flex;
}
.Asterisk {
  font-weight: bolder;
  margin-left: 3px;
  margin-bottom: 0px !important;
}
.InputLabelFld {
  margin-bottom: 10px;
}
/* .ButtonWrapper .loginButton:first-child {
    margin-right: 8px;
} */
/* .ButtonWrapper .loginButton:last-child {
    margin-right: 8px;
} */
.TabBoxSecond .loginButton {
  margin-left: auto !important;
  margin-right: auto !important;
}
.CommonTabpanel {
  padding-bottom: 16px;
  padding-top: 16px;
}
.AlertDismissButton {
  white-space: nowrap;
}
.MuiSnackbar-root .MuiAlert-icon {
  align-items: center;
}
.mb-2 {
  margin-bottom: 8px;
}
.ml-2 {
  margin-left: 8px;
}
.mr-2 {
  margin-right: 8px;
}
.CommonTabNav {
  border-bottom: 1px solid #e2e1d5;
  padding-left: 10px;
}
.ButtonProgress {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
}
.LinkTypeButton {
  padding: 0px;
  min-width: 0px;
  min-height: 0px;
  border-bottom: none;
  border-radius: 0px;
  font-weight: 500;
}
.LinkTypeButton:hover {
  background-color: transparent;
  box-shadow: none;
  border-bottom: none;
}
.LinkTypeButton .MuiButton-label {
  text-transform: none;
}
.loginheading {
  margin-top: 2px;
  margin-bottom: 0px;
  text-align: center;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.87);
  white-space: nowrap;
}
.Small {
  width: 32px;
  height: 32px;
  background-color: #edeff2;
}
.Large {
  width: 66px;
  height: 66px;
  background-color: #edeff2;
}
.Medium {
  width: 48px;
  height: 48px;
  background-color: #edeff2;
}
.RedColor {
  color: #c72943;
}
.OrangeColor {
  color: #d68649;
}
.GreenColor {
  color: #486877;
}
.LightGreenColor {
  color: #569ca4;
}
.CutGrassGreenColor {
  color: #919d86;
}
.Darkgray {
  color: #545d78;
}
a[color='primary'] {
  color: #486877;
}
a[color='primary']:hover {
  color: #569ca4;
}
.RightFloat {
  display: flex;
  justify-content: flex-end;
  font-weight: 500;
  /* position: relative;
  right:'550px'; */
}
.RightFloatBtn {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  font-weight: 500;
  letter-spacing: 2px;
  font-size: 20px;
  text-transform: uppercase;
}
.MobileHeaderTopNav .MuiListItemText-primary {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
  font-size: 16px;
}
.Lightgray {
  color: rgba(0, 0, 0, 0.6);
}
.HeaderNavLogo {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 20px 20px;
  flex-direction: column;
}

.MuiLink-root.MuiTypography-colorPrimary,
.MuiLink-root.MuiTypography-colorPrimary:focus {
  color: #486877;
}
.MuiLink-root.MuiTypography-colorPrimary:hover {
  color: #569ca4;
}
.MuiLink-root.MuiTypography-colorSecondary,
.MuiLink-root.MuiTypography-colorSecondary:focus {
  color: #c72943;
}
.MuiLink-root.MuiTypography-colorSecondary:hover {
  color: #e57184;
}
.MuiListItem-root.Mui-selected .MuiTypography-root {
  color: #d4a569;
}
.MuiAlert-message {
  font-size: 14px;
  font-weight: 400;
}
.MuiAlert-action a {
  font-weight: 400;
}
.ButtonWrapper {
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 24px;
  position: relative;
}
.FlexCenterStart,
.SearchBoxContainer {
  display: flex;
  align-items: center;
}
.TabBoxSecond {
  display: grid;
  margin-left: auto;
  margin-right: auto;
  max-width: 375px;
}
textarea {
  border: 1px solid #e2e1d5;
  border-radius: 8px;
  min-width: 100%;
  margin-top: 12px;
  resize: none;
  padding: 15px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
}
textarea:hover,
textarea:focus {
  box-shadow: 0px 1px 10px #95c8c2;
  border-color: #95c8c2;
  border-width: 0px;
}
textarea.MuiInputBase-input {
  background-color: transparent;
  box-shadow: none;
  line-height: 24px;
}
textarea:focus-visible {
  outline: none;
}
.MaterialTableContainer {
  background-color: #fff;
  border-radius: 8px;
  padding: 10px 20px;
}
.MaterialTableContainer .MuiTable-root .MuiTableCell-root {
  font-size: 16px !important;
}
/* .MaterialTableContainer .MuiTable-root .MuiTableCell-root .MuiTypography-root {
  font-size: 16px !important;
}
.MaterialTableContainer .MuiTable-root .MuiTableCell-root .UserTableEmail  {
   font-size: 14px !important;
 } */
.MaterialTableContainer .MuiTable-root .MuiTableCell-root.MuiTableCell-body {
  border-bottom: none !important;
}
.MaterialTableContainer .MuiTable-root .MuiTableCell-root.MuiTableCell-head {
  border-bottom: 1px solid transparent !important;
  padding: 0px;
}
.MaterialTableContainer .MuiTableSortLabel-root {
  border-bottom: 1px solid #e2e1d5;
  width: 100%;
  padding: 12px;
}
.MuiTableRow-root {
  color: rgba(0, 0, 0, 0.6) !important;
}
.MuiTableCell-head {
  background-color: #fff !important;
  color: rgba(0, 0, 0, 0.6) !important;
}
.MuiTableRow-root:hover {
  background-color: #fff2e0 !important;
  /* color: #fff !important; */
}
.MaterialTableContainer > .MuiPaper-root {
  box-shadow: none;
  background-color: transparent;
}
.MuiTableSortLabel-active .MuiSvgIcon-root {
  transform: rotate(180deg);
}
.MuiTableCell-root.MuiTableCell-body .MuiButtonBase-root {
  padding: 0;
}
.CommunityRepresentativeProfile .TextFieldTextarea,
.CommunityEditViewAbout .TextFieldTextarea {
  margin-top: 0px;
}
.TextFieldTextarea .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.TextFieldTextarea .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-width: 0px;
}
.DialogTitleHeading {
  font-family: Arima, cursive;
  font-weight: 600;
}
.TextFieldTextarea textarea {
  overflow: scroll !important;
  margin-bottom: 40px;
}
.TextFieldTextarea .MuiFormHelperText-root.MuiFormHelperText-contained {
  background-color: rgba(226, 225, 213, 0.3);
  margin: 0px 0 0 0;
  padding: 10px;
  text-align: right;
  border-radius: 0 0 8px 8px;
  position: absolute;
  z-index: 99;
  width: calc(100% - 2px);
  bottom: 1px;
  left: 1px;
  border-top: 1px solid #e2e1d5;
  font-weight: 600;
  font-size: 16px;
}
.TextFieldTextarea .MuiFormHelperText-root.MuiFormHelperText-contained::after {
  content: 'characters remaining';
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  display: inline-block;
  font-weight: 400;
  margin-left: 5px;
}
.TextFieldTextarea .MuiFormHelperText-root.MuiFormHelperText-contained.Mui-focused,
.TextFieldTextarea .MuiFormHelperText-root.MuiFormHelperText-contained.MuiFormHelperText-filled {
  color: rgba(0, 107, 54, 1);
}
.SearchBox {
  margin: 20px 10px 32px 0px;
  max-width: 65%;
  width: 100%;
}
.TableSearchBoxContainer .SearchBox {
  margin: 0px 10px 0px 0px;
}
.TableSearchBoxContainer {
  margin: 20px 0px 32px 0px;
}
.MobileSearchTextBox {
  margin-bottom: 20px;
}
.InnerContainer {
  min-width: 435px;
}
.MobileHeaderTopNav .MuiPopover-paper {
  width: 100%;
  top: 0px !important;
  /* top: 80px !important; */
  left: 0 !important;
  max-width: 100%;
  border-radius: 0;
  height: 100vh;
  max-height: 100vh;
}
.MobileHeaderTopNav .Copyright {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
  position: fixed;
  width: 100%;
  padding: 0 20px;
  bottom: 0;
  background-color: #fff;
}
.TextPara.MuiTypography-root {
  margin-bottom: 16px;
}
.DialogTitleLeft {
  text-align: left;
}
.DialogTitleLeft .MuiTypography-root,
.FlexSide {
  display: flex;
  justify-content: space-between;
}
.FlexSide {
  align-items: center;
}
.DialogTitleLeft .MuiTypography-root svg {
  margin-left: 10px;
  color: rgba(0, 0, 0, 0.6) !important;
  cursor: pointer;
}
.TextColor {
  color: rgba(0, 0, 0, 0.87) !important;
}
.invalid-feedback,
.TextFieldTextarea + .MuiTypography-colorError,
.CustomError {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  margin-top: 8px;
  margin-left: 0;
  padding-left: 24px;
  background-image: url(assets/images/Error-Icon.svg);
  background-repeat: no-repeat;
  background-position: left 2px;
}
.FullWidthPattern {
  background: url(assets/images/LightGreen-Pattern-Bg.png) repeat;
  background-size: auto;
  min-height: 100vh;
  align-items: center;
  display: flex;
  margin-top: -80px;
}
.FixedMD {
  /* max-width: 820px; */
  max-width: 790px;
  margin-left: auto;
  margin-right: auto;
}
.FixedID {
  max-width: 700px;
  /* margin-left: 44px; */
  margin-left: auto;
  margin-right: auto;
}
.InvitationWrapper {
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.12);
  padding: 35px 35px 35px 35px;
}
.HouseInfoBox:last-child {
  border: none;
}
.HouseInfoBox {
  border-right: 1px solid rgba(0, 0, 0, 0.08);
}
.MuiToggleButton-root.Mui-selected {
  background-color: transparent;
  box-shadow: none;
}
.WhitePaper {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 20px 0px;
  margin-bottom: 30px;
}
.ScrollerBoxCD .WhitePaper,
.HomesBoxCD {
  background-image: url(assets/images/BreakLower.svg);
  background-position: top center;
  background-repeat: no-repeat;
  padding: 70px 30px 20px 30px;
}
.TopBoxCD,
.ViewPublicBox {
  padding: 30px 30px 30px 30px;
  border-radius: 8px;
}
.ScrollerBoxCD .WhitePaper.EmptyWhitePaper {
  padding: 0px;
}
.BannerButton {
  background-color: #e4bc79;
  color: rgba(0, 0, 0, 0.87);
}
.TransparentPopover .MuiPopover-paper {
  box-shadow: none;
  border-radius: 0px;
  background-color: transparent;
}
.TransparentPaper {
  box-shadow: none;
  border-radius: 0px;
}
.CommonEmptyNote .MuiTypography-root {
  margin-bottom: 16px;
}
.CommonImg img {
  width: 100%;
  height: auto;
}
.MapImg img {
  border-radius: 0px;
  display: flex;
}
.BoxHeading {
  margin-bottom: 20px;
}
a {
  cursor: pointer;
}
.communityDetailsContent .CommonCardColumn {
  width: 100% !important;
  max-width: 100% !important;
}
.EditIcon {
  position: absolute;
  top: 18px;
  right: 58px;
  color: #569ca4;
  cursor: pointer;
}
.EditText {
  position: absolute;
  top: 18px;
  right: 8px;
  color: #569ca4;
  cursor: pointer;
}
.ModalHelpertext {
  margin-left: auto;
  position: relative;
  top: 20px;
}
.FacilityIcons {
  color: #569ca4;
  /* position: 'fixed';
  top:'50px'; */
  margin-right: 15px;
}
.FacilityCardText {
  position: 'relative';
  top: '50px';
}
.communityDetailsContent .CommonCardColumn .MuiCard-root {
  margin-right: 0px;
  border-radius: 4px;
  margin-bottom: 30px;
  position: relative;
  margin-left: 0;
}
.communityDetailsCarousel .CommonCardColumn {
  width: 100% !important;
  max-width: 100% !important;
}
.communityDetailsCarousel .CommonCardColumn .MuiCard-root {
  border-radius: 4px;
  margin-left: 4px;
}
.White {
  color: #fff;
}
.active {
  background: url(assets/images/LeftIcon.svg) no-repeat left center;
}
.PatternDivider {
  background: url(assets/images/BreakUpper.svg) no-repeat center center;
  min-height: 50px;
  display: flex;
  margin-top: -20px;
  position: relative;
  z-index: 99;
  background-size: cover;
}
.PatternDividerBottom {
  background: url(assets/images/BreakLower.svg) no-repeat center center;
  min-height: 50px;
  display: flex;
  margin-top: 0px;
  position: relative;
  z-index: 99;
  background-size: cover;
}
.FooterContainer {
  position: relative;
  /* background: url(assets/images/MTH-Butterfly.png) no-repeat;
  background-position: top 40px right; */
}
.CarouselInner {
  position: relative;
}
.read-or-hide {
  color: #d68649;
  cursor: pointer;
}
.SearchButton {
  font-weight: 700;
  font-size: 16px;
}
.EmptyNoteBox {
  display: table;
  margin: 0 auto;
  position: relative;
}
.FavoritesEmptyNote svg.SearchIcon {
  width: 40px;
  height: 40px;
}
.EmptyNoteImg {
  position: relative;
}
svg.EmptyNoteIcon {
  position: absolute;
  font-size: 60px;
  bottom: 0;
  right: 0px;
}
.CommonEmptyNote {
  text-align: center;
  position: absolute;
  top: 30px;
  min-width: auto;
  width: 450px;
  left: 50%;
  transform: translate(-50%, 0px);
}
.HealthCard {
  margin-bottom: 16px; /* Adjust the value to control the spacing between cards */
  background-color: #e4f4f5;
  /* #E4F4F5  */
  /* #D8EFF1 */
  position: relative;
}
.FullCommonCard .MuiCardMedia-root {
  max-height: 200px;
  overflow: hidden;
}
.FullCommonCard .CommonCardImgBox {
  max-height: 200px;
  overflow: hidden;
  cursor: pointer;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.communityDetailsContent .FullCommonCard .CommonCardImgBox img {
  width: 100%;
  object-fit: cover;
  max-height: 200px;
}
.FlexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.SearchBox .MuiInputAdornment-root {
  margin-right: 10px;
  cursor: pointer;
}
.MuiDialogActions-root button {
  width: 50%;
}
.NoBorderButton {
  border: 1px solid transparent;
  background-color: transparent;
}
.MuiDialogActions-root button {
  font-size: 18px;
  font-weight: 600;
}

.CloseButton {
  cursor: pointer;
}
.SearchButton {
  white-space: nowrap;
}
.SearchButton:hover svg,
.SearchButton:focus svg {
  color: #569ca4;
}
.CommonAutocomplete {
  width: 100%;
  min-width: 200px;
}
.AutocompleteBox {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.AutocompleteBoxAdmin {
  margin-top: 0px;
  min-width: 30%;
}
.AutocompleteBoxAdmin.SelectCommunity {
  margin-top: 20px;
}
.AutocompleteBoxAdmin .MuiAutocomplete-input {
  padding: 8px 66px 8px 12px !important;
}
.AutocompleteBoxAdmin .MuiInputLabel-outlined {
  transform: translate(10px, 10px) scale(1);
}
.AutocompleteBoxAdmin .MuiInputLabel-shrink,
.AutocompleteBoxAdmin legend span {
  display: none;
}
.AutocompleteBoxAdmin .MuiInputLabel-root {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
}
.DashIcon {
  margin: 0 8px;
}
.AutocompleteStartIcon {
  position: relative;
}
.DollarIcon {
  position: absolute;
  left: 7px;
  top: 50%;
  z-index: 99;
  transform: translate(0px, -50%);
}
.AutocompleteStartIcon .MuiAutocomplete-input {
  padding: 6px 66px 6.5px 34px !important;
}
.FilterDropdownContent {
  margin: 15px 0 15px 0;
}
.FilterDropdownContent:first-child {
  margin-top: 0px;
}

.FilterDropdownBox {
  margin-top: 10px;
}
.FilterDropdownContent input::placeholder {
  color: rgba(0, 0, 0, 0.6);
  opacity: 1;
}
.PlacesAutocompleteFld input::placeholder {
  color: rgba(0, 0, 0, 0.4);
  opacity: 1;
}
.SearchBox input::placeholder {
  color: rgba(0, 0, 0, 0.38);
  opacity: 1;
}
.OnboardingBg {
  text-align: center;
  margin-bottom: 10px;
}
/* img.ConeIcon {
  position: absolute;
  left: 25px;
  top: 15px;
} */
/* img.HeartIcon {
  position: absolute;
  left: 25px;
  top: 15px;

  width: 100px;
} */
.InvitationRightImage {
  position: absolute;
  left: auto;
  right: 0;
  width: auto;
  top: 190px;
}
.InvitationTopImage {
  position: absolute;
  left: 0;
  top: 40px;

  opacity: 0.7;
}
.InvitationBottomImage {
  position: absolute;
  left: 0;
  top: 602px;

  opacity: 0.7;
}
.OnboardingImgContainer {
  position: relative;
  /* width: 137px; */
  margin: auto;
  /* opacity: 0.7; */
}
.DeclineTitle {
  /* color: rgba(0, 0, 0, 0.87); */
  /* text-align: center; */
  /* font-size: 26px; */
  /* font-family: Source Serif Pro; */
  /* line-height: 32px; */
}
.DeclineHeading {
  color: rgba(0, 0, 0, 0.87);
  font-size: 20px;
  font-family: Source Serif Pro;
  font-weight: 600;
  line-height: 24px;
}
.DeclineButton,
.DeclineButton:hover {
  border-radius: 8px;
  background: #b51c36;
  width: 343px;
}
.MuiAutocomplete-popper .MuiCheckbox-root {
  padding: 0px 0px;
  margin-right: 5px !important;
}
.CommonButtonTypeTab .MuiTabs-flexContainer {
  border: 1px solid #e2e1d5;
  border-radius: 8px;
  display: inline-block;
}
.CommonButtonTypeTab .MuiTab-textColorInherit {
  color: #486877;
  text-transform: uppercase;
  font-weight: 700;
  min-width: 225px !important;
  padding: 10px;
  margin-right: 0px;
}
.CommonButtonTypeTab .MuiTab-textColorInherit.Mui-selected {
  color: rgba(0, 0, 0, 0.6);
  background-color: #e5efeb;
}
.CommonButtonTypeTab .MuiTabs-indicator {
  display: none;
}
/* Invitation End */
.LeaveCommunityBtnWrapper {
  margin-bottom: 12px;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}
.MaterialTableContainer ::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  border-radius: 10px;
  background-color: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.38);
  border-radius: 10px;
}
.CustomTableContainer .MuiTableSortLabel-icon {
  font-size: 1.5rem;
  opacity: 1;
}
.CustomTableContainer .MuiTableCell-body {
  color: inherit;
  box-sizing: border-box;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
}
.CustomTableContainer th {
  max-width: 240px;
  border-bottom: 1px solid transparent !important;
  padding: 0px;
}
.CustomTableContainer .TableContainer {
  max-height: 400px;
  overflow-y: auto;
}
@media screen and (max-device-width: 1279px) {
  .InvitationRightImage,
  .InvitationTopImage,
  .InvitationBottomImage {
    display: none;
  }
  .InvitationContent {
    width: 100%;
  }
}
@media screen and (min-device-width: 767px) {
  .DeleteDialog .MuiDialog-paperWidthSm {
    min-width: 520px;
  }
  .MaxModal .MuiDialog-paperWidthSm {
    max-width: 520px;
  }
}
@media screen and (min-device-width: 1024px) {
  .MuiAlert-root {
    min-width: 400px;
  }
     .NoWrap {
       font-size: 15px;
     }
}
@media screen and (max-device-width: 1024px) {
  .FixedMD {
    max-width: 100%;
  }
  .FixedID {
    max-width: 100%;
    margin-left: 0px;
  }
  
}
@media screen and (max-device-width: 800px) {
  .MobileFilter .MuiPopover-paper {
    width: 100%;
    top: 0px !important;
    /* top: 80px !important; */
    left: 0 !important;
    max-width: 100%;
    border-radius: 0;
    height: 100vh;
    max-height: 100vh;
  }
  .CommonAutocomplete {
    min-width: auto;
  }
  .FilterDropdownContent .AutocompleteStartIcon,
  .FilterDropdownContent .AutocompleteNoIcon {
    width: calc(100% - 22px);
  }
}
@media screen and (min-device-width: 768px) {
  .MuiRadio-root + .MuiFormControlLabel-label,
  .MuiCheckbox-root + .MuiFormControlLabel-label {
    font-size: 18px;
  }
}
@media screen and (max-device-width: 600px){
  .FlexCenterStart.PaginationNumberContainer {
      display: grid;
    }
}
@media screen and (max-device-width: 767px) {
 
 .PaginationNumber {
          margin-top: 20px;
        }
  .TableSearchBoxContainer {
      margin: 0px 0px 0px 0px;
    }
  .MobileHeaderTopNav .MuiPopover-paper .HeaderNavLogo .MainLogobox {
    justify-content: flex-start;
  }
.MobileHeaderTopNav .MuiPopover-paper .HeaderNavCloseButton {
      display: flex;
      margin-left: auto;
      margin-bottom: 15px;
    }
  .BasicPagination .MuiPaginationItem-root {
    height: 30px;
    width: 30px;
  }
  .MuiDialogActions-root button {
    font-size: 14px;
    white-space: nowrap;
  }
  .RightFloatBtn {
    font-size: 16px;
  }
  .CustomContainer {
    padding-left: 0px;
    padding-right: 0px;
  }
  .TopBoxCD,
  .ViewPublicBox {
    margin-bottom: 0px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .PageSpacing.communityDetailsContainer {
    padding-top: 25px;
  }
  .ScrollerBoxCD .WhitePaper,
  .HomesBoxCD,
  .ViewPublicBox,
  .TopBoxCD {
    padding-left: 16px;
    padding-right: 16px;
  }
  .TopBoxCD,
  .ScrollerBoxCD .WhitePaper,
  .HomesBoxCD,
  .ViewPublicBox,
  .communityDetailsContent .CommonCardColumn .MuiCard-root {
    border-radius: 0px;
  }
  .SearchBox {
    width: calc(100% - 108px);
  }
  .ButtonWrapper .loginButton:first-child {
    margin-right: 0px;
  }
  .ButtonWrapper .loginButton:last-child {
    margin-left: 0px;
    margin-top: 20px;
  }
  .loginheading {
    margin-top: 0px;
    margin-bottom: 0px;
    white-space: normal;
  }
  .CarouselInner .FullCommonCard {
    margin-right: 30px;
  }
  .CommonEmptyNote {
    width: 280px;
  }
  .MainHeading {
    text-align: center;
  }
  .ButtonWrapper {
    display: grid;
    margin-top: 24px;
  }
  .Fullwidht {
    width: 100%;
  }
  .InnerContainer {
    min-width: 100%;
  }
  .TextCenterXS {
    text-align: center;
  }
  .CustomDialog .MuiPaper-root.MuiDialog-paper {
    min-width: 100%;
    margin-top: 0px;
    height: 100%;
    max-height: calc(100% - 0px);
    margin-bottom: 0px;
  }
  .CustomDialog .MuiPaper-rounded {
    border-radius: 0px;
  }
  .CustomDialog .MuiDialog-container.MuiDialog-scrollPaper {
    align-items: flex-start;
  }
  .CustomDialog .CustomCloseIcon {
    background: none;
  }
  .CustomDialog .CustomCloseIcon .MuiSvgIcon-fontSizeSmall {
    font-size: 24px;
  }
}
