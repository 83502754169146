/* .loaderouter {
  display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
} */

/* copyLinkText: {
  display: 'flex',
  flex: '1',
  // padding: '16px 18px',
  '& .MuiTypography-h2': {
    fontWeight: '700',
  }
  ,
  '& p': {
    color: 'rgba(0, 0, 0, 0.6)',
      fontSize: '14px',
      lineHeight: '16px',
      // marginLeft: '40px',
  }
  ,
} */
/* 
 copyLinkBox: {
   borderRadius: '8px',
     border: '1px solid #E2E1D5',
     padding: 10,
     display: 'flex',
     justifyContent: 'space-between',
     alignItems: 'center',
     // height: '100px',
 }
 , */

/* 
 copyIcon: {
   // height: '100px',
   display: 'flex',
   // backgroundColor: '#F88700',
   // padding: '18px',
   borderRadius: '0 8px 8px 0',
   cursor: 'pointer',
   '& path': {
     fill: 'rgba(0, 0, 0, 0.6)',
   }
   ,
   '& .MuiSvgIcon-root': {
     // marginTop: '75%',
   }
   ,
 }
 , */
 /* copiedClipboard: {
   backgroundColor: '#F88700',
   borderRadius: '8px 8px 0 0',
   position: 'absolute',
   bottom: 0,
   left: 0,
   right: 0,
   margin: 'auto',
   width: '300px',
   height: '40px',
   '& .MuiTypography-h3': {
     color: '#fff',
       display: 'flex',
       height: '100%',
       alignItems: 'center',
       justifyContent: 'center',
   }
   ,
 } */
 

.copyIcon {
  margin-left: 20px;
  cursor: pointer;
}
.copyLinkText {
  background: #FFFFFF;
  border: 1px solid #E2E1D5;
  border-radius: 8px;
  padding: 10px 8px;
  display: flex;
    align-items: center;
}
.copyLinkText p {
  white-space: nowrap;
  width: calc(100% - 39px);
  overflow: hidden;
  font-size: 16px;
}
@media screen and (max-device-width: 345px) {

.ShareTitle {
  font-size: 17px;
}
}