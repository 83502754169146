/* eslint-disable  */
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff !important;
}
a {
  text-decoration: none;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  border-radius: 10px;
  background-color: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.38);
  border-radius: 10px;
}

/* :root {
  scrollbar-color: rgb(210, 210, 210) rgb(46, 54, 69) !important;
  scrollbar-width: thin !important;
} */
