/* .CommonCard .MuiCardContent-root:last-child {
  padding-bottom: 16px;
} */
 .ViewHomesButton {
  display: none;
 }
.communityDetailsContainer {
  padding-bottom: 80px !important;
}
.DeleteAccountSuccessPage {
  margin-top: -80px;
  height: 100vh;
}
.CommunityListPage .CountHeading {
  font-size: 13px;
  font-weight: 600;
}
.AutocompleteDropdownContainer {
  background: #fff;
  border-radius: 8px;
  margin-top: 5px;
}
.AccountFavoritesContent .CommunityListPage .MuiContainer-root {
  padding-left: 0px;
  padding-right: 0px;
}
.AccountFavoritesContent .CommunityListPage {
  padding-top: 0px;
  padding-bottom: 0px;
}
.BorderTop {
  border-top: 1px solid #e2e1d5;
}
.LogoutButton {
  justify-content: space-between;
  width: 100%;
}
.SuggestionItem {
  padding: 8px 12px;
  cursor: pointer;
  font-family: Urbanist;
  font-size: 16px;
  font-weight: 500;
}
.SuggestionItem:hover,
.SuggestionItem--active:hover {
  background-color: #fff2e0;
  padding: 8px 12px;
  cursor: pointer;
  font-family: Urbanist;
  font-size: 16px;
  font-weight: 500;
}
.JobEmptyStateContent {
  border: 2px solid #569ca4;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 16px 20px 16px;
  margin-bottom: 25px;
}
.JobEmptyStateContent .MuiTypography-root {
  margin-top: 10px;
}
.InstructionLink svg {
  margin-left: 5px;
}
.InstructionLink.FlexCenterStart {
  display: inline-flex;
}
.MuiFormControlLabel-root.FacilityListShowHideButton {
  width: 100%;
  margin: 15px 0 0 0;
  border-top: 1px solid #e2e1d5;
}
.BackButtonWrapper {
  display: inline-block;
}
.AboutChatBoxWrapper .UserInfoIP {
  margin-bottom: 0px;
}
.ViewAllAmenitiesContainer .AddAmenityContentBox,
.ViewAllAmenitiesContainer .AddAmenityDividerRow {
  display: none;
}
.ChatBoxHealthcare .ChatContent {
  margin-top: 50px;
}
.ResidentsModule .UserInfoIP {
  margin-bottom: 0px;
}
.MuiSwitch-root.ShowHideButton {
  display: flex;
  height: 44px;
  padding: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 16px 16px;
  background: #e2e1d5;
  width: auto;
  min-width: 155px;
  margin: 0 auto;
}
.ResidentsModule .ChatContent {
  margin-top: 30px;
}
.CommunityEditViewResidents .DividerCD {
  margin-top: 20px;
  margin-bottom: 10px;
}
.HealthcareModule .FacilityListFilter .DividerCD {
  margin-bottom: 30px;
}
.FacilityListCollapse {
  width: 100%;
}
.FacilityListCollapseRow {
  border-top: 1px solid #e2e1d5;
  margin-top: 15px;
}
.MuiSwitch-root.ShowHideButton .MuiSwitch-switchBase {
  position: relative;
}
.MuiSwitch-root.ShowHideButton .MuiIconButton-label {
  display: none;
}
.MuiSwitch-root.ShowHideButton .MuiSwitch-thumb {
  background-color: transparent;
}
.MuiSwitch-root.ShowHideButton .MuiSwitch-track {
  display: none;
}
.MuiSwitch-root.ShowHideButton .MuiSwitch-switchBase::before {
  display: block;
  content: 'Show';
  color: #486877;
  text-align: center;
  font-family: Urbanist;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 2px;
  text-transform: uppercase;
  background: url(../../assets/images/ChevronDownBig.svg) no-repeat left center;
  padding-left: 30px;
}
.MuiSwitch-root.ShowHideButton .MuiSwitch-switchBase.MuiIconButton-root:hover {
  background-color: transparent;
}
.MuiSwitch-root.ShowHideButton .MuiSwitch-switchBase.Mui-checked::before {
  content: 'Hide';
  background: url(../../assets/images/ChevronUpBig.svg) no-repeat left center;
}
.ViewAllAmenitiesContainer .SearchBoxContainer {
  margin-top: 30px;
  padding-left: 15px;
  padding-right: 15px;
}
.SuccessVideoApproved,
.SubmissionVideoRejected,
.WarningAlertBox {
  border-radius: 4px;
  display: flex;
  padding: 16px;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 30px;
}
.WarningAlertBox.ChangeAccountTypeAlert {
  margin-top: 40px;
  margin-bottom: 10px;
}
.SuccessVideoApproved {
  border: 1px solid #006b36;
  background: #e5efeb;
}
.SubmissionVideoRejected {
  border: 1px solid #c72943;
  background: #ffeaeb;
}
.SuccessVideoApproved.ReceivedMessage {
  border: 1px solid #919d86;
  background: rgba(226, 225, 213, 0.3);
}
.OrangeColorBox {
  border: 1px solid #d4a569;
  background: #fff2e0;
}
.SuccessVideoApproved .MuiIconButton-root,
.SubmissionVideoRejected .MuiIconButton-root {
  padding: 0 !important;
}
.VideoApprovedHeading,
.WarningAlertHeading {
  margin-bottom: 5px;
}
.SuccessVideoInfo,
.SubmissionVideoInfo,
.WarningAlertInfo {
  display: flex;
}
.SuccessVideoText,
.WarningAlertText,
.SubmissionVideoText {
  margin-left: 20px;
  margin-right: 20px;
}
.VideoApprovedReason {
  margin-top: 15px;
}
.InsightLabel.AboutInsightLabel {
  margin-top: 0px;
}
.SuccessVideoInfo svg,
.SubmissionVideoInfo svg {
  min-width: 24px;
}
.AboutVideoMainBoxEdit .LinkTypeButton {
  font-weight: 700;
}
.AboutVideoMainBoxEdit .LinkTypeButton .MuiButton-label {
  text-transform: uppercase;
}
.AboutVideoMainBox video {
  border-radius: 8px;
  margin-bottom: 25px;
}
.AboutVideoMainBoxEdit {
  margin-bottom: 20px;
  justify-content: flex-end;
  display: flex;
}
.DropContainer {
  display: flex;
  padding: 32px 24px 32px 24px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  border: 1px rgba(0, 0, 0, 0.38) dashed;
  border-radius: 8px;
  margin-top: 20px;
}
.NowrapButton {
  white-space: normal !important;
  word-break: normal;
}
.dropzonestyle {
  text-align: center;
}
.BrowseButton {
  margin-left: 10px;
  border-bottom: 2px solid rgba(221, 160, 145, 1);
  cursor: pointer;
}
.DropzoneInfo {
  margin-top: 10px;
  margin-bottom: 25px;
  display: block;
}
.DropzoneNote li::before {
  content: '';
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.87);
  width: 2px;
  height: 2px;
  border-radius: 100%;
  margin-right: 10px;
}
.VideoHeading {
  margin-bottom: 5px;
}
.UploadMessageBox {
  display: flex;
}
.DropzoneSuccess {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
.DropWrapper {
  width: 100%;
}
.VideoSuccess {
  min-width: 40px;
}
.DropzoneActionButton {
  width: 92px;
  justify-content: flex-end;
  display: flex;
}
.VideoSuccessInfo {
  width: 80%;
}
.DropzoneIcon {
  margin-bottom: 10px;
}
.UploadVideoPaper {
  display: flex;
  padding: 16px 16px 16px 16px;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #cbcedf;
  background: #fff;
  box-shadow: none;
  /* margin-top: 30px; */
}
.UploadVideoCheckboxWrapper {
  margin-left: 0;
  margin-right: auto;
  border-radius: 28px;
  background: #f8f9fc;
  padding: 2px 10px 2px 5px;
  margin-bottom: 20px;
}
.UploadVideoHeading {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
.UploadVideoSubheading {
  text-align: center;
  margin-bottom: 20px;
  max-width: 570px;
}
.UploadVideoButton {
  margin-bottom: 30px;
  font-size: 16px;
  letter-spacing: 2px;
}
.GreenDividerLogo {
  position: relative;
}
.GreenDivider {
  margin-top: 35px;
  margin-bottom: 35px;
}
.ModalRequestVideoConsultation .MuiDialog-paperWidthSm {
  max-width: 480px;
}
.ModalRequestVideoConsultation .commonFieldBox {
  margin-top: 20px;
  margin-bottom: 0px;
}
.ModalRequestVideoConsultation .AboutVideoBox {
  margin-top: 20px;
  display: flex;
}
video[poster] {
}
.ModalRequestVideoConsultation .AboutVideoBox video {
  border-radius: 12px;
}
.DividerLogo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.VideoTeamButton {
  margin-bottom: 20px;
}
.VideoTeamButton.LinkTypeButton .MuiButton-label {
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 2px;
}
.UploadVideoCheckboxWrapper .MuiFormControlLabel-label {
  font-size: 14px;
}
.UploadVideoCheckboxWrapper .MuiCheckbox-root {
  color: #636465;
}
.MobileFacilityList {
  display: none;
}
.FacilityList,
.FacilityListFilter {
  margin-top: 25px;
}
.ChatBoxHealthcare .EditCommonButtonFloat {
  top: -25px;
  background-color: #fff;
  min-width: auto;
}
.FacilityListContent {
  /* width: 100%; */
  margin-bottom: 70px;
}
.FacilityListHeading {
  font-weight: 600;
  padding-left: 20px;
  padding-right: 20px;
}
.FacilityInfo {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.FacilityFilterBox,
.AmenitiesFilterBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.AmenitiesFilterBox {
  margin-top: 20px;
  margin-bottom: 20px;
}
.FacilityEditButton .MuiButton-label {
  text-transform: uppercase !important;
  font-weight: 600;
}
.FacilityEditButton {
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
}
.FacilityNameBox {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
}
.FacilityNameBox,
.FacilityInfo {
  padding-left: 20px;
  padding-right: 30px;
}
.FacilityListName {
  width: calc(100% - 80px);
  color: #486877;
  text-decoration: underline;
}
.FacilityListName {
  font-weight: 600;
}
.FacilityInfo svg {
  min-width: 16px;
  height: 16px;
}
.FacilityInfoContent {
  margin-left: 10px;
}
.DeclineTitle {
  margin-bottom: 15px;
  font-family: 'Arima', cursive;
}
.DeclineSubTitle {
  margin-bottom: 20px;
}
.JobPostFilter {
  border-radius: 15px;
  border: 1px solid #cbcedf;
  background: #fff;
  padding: 20px 20px;
  margin-top: 15px;
  box-shadow: none;
  max-width: 90%;
  float: right;
}
.HeadingLCCaption {
  font-weight: 600;
  margin-bottom: 10px;
}
.JobPostFilter .MuiOutlinedInput-root {
  /* border: 1px solid #aeb3ce; */
}
.PaginationWrapper {
  margin-top: 20px;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  display: table;
}
.FilterPopover .JobPostFilter {
  max-width: 300px;
  margin-top: 0px;
}
.FilterMobileBox {
  margin-top: 20px;
  justify-content: flex-end;
  display: flex;
}
.JobPostFilter .FilterCheckbox .MuiFormControlLabel-label {
  /* color: rgba(0, 0, 0, 0.87); */
  /* font-size: 14px; */
  /* font-weight: 600; */
}
.JobPostFilter .FilterCheckbox .MuiCheckbox-colorPrimary {
  /* color: #aeb3ce; */
}
.JobPostFilter .FilterCheckbox .MuiCheckbox-colorPrimary.Mui-checked {
  color: #486877;
}
/* JobDetails start */
.JobDetailsApplyWrapper .JobDetailsLogo {
  margin: 0 auto;
  border-radius: 8px;
}
.JobDetailsSummary {
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 20px;
}
.JobDetailsApply {
  background: #fff;
  padding: 20px 20px;
  width: 90%;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.12);
  text-align: center;
  float: right;
}
.JobDetailsApply.Top {
  border-bottom: 1px solid #e2e1d5;
  border-radius: 10px 10px 0px 0px;
}
.JobDetailsApply.Bottom {
  border-radius: 0px 0px 10px 10px;
}
.JobDetailsLogo img {
  width: 70px;
  border-radius: 8px;
  /* margin-right: 15px; */
}
.JobDetailsApplyEmpName {
  font-weight: 600;
  margin-bottom: 16px;
}
.JobDetailsShare {
  font-weight: 600;
}
.JobDetailsShareContentBox {
  display: inline-flex;
}
.JobDetailsShareContent {
  margin: 14px 12px 0px 0px;
}
.JobDetailsShareContent:last-child {
  margin-right: 0px;
}
.JobDetailsSubHead {
  margin-bottom: 16px;
}
.JobDetailsTitle {
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.87);
}
.JobDetailsApplyWrapper .JobDetailsLogo .MuiAvatar-root {
  width: 70px;
  height: 70px;
  margin-bottom: 5px;
  color: #486877;
}
.JobDetailsSubTitle {
  margin-bottom: 20px;
}
.JobDetailsDot {
  background: rgba(0, 0, 0, 0.6);
  width: 4px;
  height: 4px;
  border-radius: 100%;
  display: inline-block;
  margin: 0 5px;
  position: relative;
  top: -2px;
}
.JobDetailsContent {
  color: rgba(0, 0, 0, 0.87);
  word-break: normal;
  overflow-wrap: anywhere;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}
/* .BackButtonJobDetails {  text-transform: uppercase;} */
/* JobDetails end*/
.SchoolCard {
  padding: 16px;
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: #fff;
  box-shadow: none;
  height: 100%;
}
.SchoolCardHeading {
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 15px;
  min-height: 44px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.GradeTypeBox {
  display: flex;
  margin-bottom: 15px;
}
.SchoolRating li {
  padding-bottom: 0px;
}
.SchoolRating li .CommonRatingCircle {
  margin-right: 10px;
  display: flex;
  justify-content: center;
  width: 40px;
}
.HelpIcon {
  display: inline-flex;
  margin-left: 10px;
}
.SchoolCardCarouselContainer,
.EducationSchoolTypes {
  margin-top: 30px;
}
.EducationSchoolTypes {
  margin-bottom: 35px;
}
/* .GettingAroundSchoolTypes .MuiTypography-root {  margin-top: 30px;} */
.GreatSchoolsGrid {
  margin-top: 30px;
  justify-content: flex-end;
  display: flex;
}
.GettingAroundModule .EmptyInsightLabel,
.ClimateModule .EmptyInsightLabel,
.ClimateTypeSection .InsightLabel,
.GettingAroundTypeSection .FirstInsightLabel {
  margin-top: 10px;
}
.EditCommonButton .MuiButton-startIcon,
.EditCommonButtonFloat .MuiButton-startIcon {
  width: 20px;
}
.selected {
  background: #e5efeb !important;
  border: 1px solid #486877 !important;
  color: #486877 !important;
}
.SlideSection {
  margin-right: 15px !important;
}
.GradeTypeNumber {
  font-weight: 700;
  margin-bottom: 3px;
  text-transform: capitalize;
}
.SchoolTypes .MuiTypography-root,
.GradeTypeNumber,
.CommonTypeSection .MuiTypography-root {
  color: rgba(0, 0, 0, 0.87);
}
.SchoolTypesHeading {
  font-weight: 600;
}
.HeadingBoxRC .UserInfoName.UserInfoIP {
  position: relative;
}
.SchoolTypes li,
.CommonTypeSection li {
  padding: 8px 0 0 10px;
}
.SchoolTypes li::before,
.CommonTypeSection li::before,
.CommonListType li::before {
  content: '';
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.87);
  width: 2px;
  height: 2px;
  border-radius: 100%;
  margin-right: 10px;
}
.SchoolTypes .InsightLabel,
.CommonTypeSection .InsightLabel {
  font-weight: 700;
  padding-left: 0px;
}
.CommonListType li {
  position: relative;
}
.CommonListType li::before {
  position: absolute;
  top: 12px;
  left: 0;
}
.InvitationContent li {
  display: block;
}
.InvitationContent li div {
  margin-left: 10px;
}
.EducationChat .ChatContent {
  margin-top: 10px;
}
.EducationChatBoxFirst {
  margin-bottom: 35px;
}
.GradeBox {
  margin-right: 20px;
}
.SchoolRating li:first-child {
  margin-bottom: 5px;
}
.SchoolRating li:first-child .CommonRatingCircle {
  height: 40px;
}
.SchoolRating li:first-child .CommonRatingCircle img {
  height: 40px;
  width: 40px;
}
.SchoolCardAddress {
  margin-bottom: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.AmenityFiltersBox {
  background-color: transparent;
  margin-top: 75px;
}
.MobileFilterBox,
.ReportMobileFilter {
  display: none;
}
.AmenityFiltersHeading {
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 20px;
}
.AmenityFiltersResultsBox {
  margin-bottom: 20px;
}
.FiltersSection {
  margin-bottom: 30px;
}
.UserNameInfo {
  margin-bottom: 5px;
  font-weight: 500;
}
.ViewAmenitiesContent .AddAmenityContentBox,
.ViewAmenitiesContent .AddAmenityDividerRow {
  padding-left: 45px;
  padding-right: 45px;
}
.SliderInfoContent {
  padding: 0 15px 10px 15px;
}
.FiltersSectionHeading {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87);
  padding-bottom: 10px;
}
.ViewAmenitiesContent .WhitePaper {
  background-color: transparent;
  box-shadow: none;
  /* padding-left: 20px;  padding-right: 20px; */
  padding-top: 0px;
}
.ViewAmenitiesContent .BoxHeading,
.ViewAmenitiesContent .FilterTagSecond.FilterTagContent {
  display: none;
}
/* .JobsModule .WhitePaper {  height: 1000px;} */
.ViewAmenitiesContent .CommonDetailBoxGridSlider {
  margin-top: 30px;
}
.FiltersOptionBox {
  max-height: 250px;
  overflow-x: auto;
  padding-bottom: 10px;
}
.AmenityAddImagesBox,
.AmenityAddImagesBoxSecond,
.CoverAddImagesBox {
  text-align: center;
  text-transform: uppercase;
  border-radius: 16px;
  display: flex;
  padding: 20px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  border-radius: 16px;
  border: 1px solid #e5efeb;
  background-color: #fff;
}
.CoverAddImagesLabel {
  margin-top: 5px;
}
.AmenityImagesContent {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 32.333333% 32.333333% 32.333333%;
  grid-gap: 10px;
}
.AmenityImagesBox {
  position: relative;
}
.AmenityImagesContent div.AmenityImagesBox:last-child {
  flex-grow: 10;
}
.AmenityImagesContent div.AmenityImagesBox:first-child .AmenityImg {
  object-fit: cover;
  min-height: 478px;
  max-height: 478px;
}
.AmenityImagesContent .AmenityImagesBox .AmenityImg {
  object-fit: cover;
  min-width: 100%;
  border-radius: 16px;
  height: 233px;
}
.AmenityImagesContent .AmenityImagesBox,
.AmenityImagesBox + .AmenityImagesUploadBox {
  max-height: 233px;
  min-height: 233px;
}
.AmenityImagesContent div.AmenityImagesBox:first-child {
  min-height: 478px;
  max-height: 478px;
  grid-column: 1 / span 2;
  grid-row: 1 / 3;
}
.AddAmenityForm,
.AddCoverForm {
  margin-top: 20px;
}
.AmenityImagesContent .AmenityImagesUploadBox:first-child {
  grid-column: 1 / span 3;
}
.EditViewButton {
  font-weight: 700 !important;
}
.EditViewButtonText,
.EditViewButton {
  max-width: 340px;
  margin: 0 auto;
}
.AmenityImagesUploadBox,
.CoverImagesUploadBox {
  display: flex;
}
.CommonImg.MapImg img {
  border-radius: 4px;
}
.ViewPublicBox svg path {
  fill: rgba(0, 0, 0, 0.6);
}
.ExitButton {
  letter-spacing: 2px;
  text-transform: uppercase;
}
.AccordionTransparent {
  background-color: transparent;
}
.AccordionTransparent .MuiAccordionSummary-root {
  display: inline-flex;
  align-items: center;
  padding: 0;
}
.AccordionTransparent .MuiAccordionSummary-root .MuiAccordionSummary-expandIcon {
  padding: 0;
  margin-left: 10px;
}
.AccordionTransparent .MuiAccordionDetails-root {
  padding: 0px;
}
.ExpandIcon {
  font-size: 32px;
}
.EmptyCardBannerOverlay {
  background-color: rgba(0, 0, 0, 0.65);
  width: 100%;
  height: 100%;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.EmptyCoverHeading,
.EmptyCoverSubHeading {
  color: #fff;
  margin-bottom: 20px;
  max-width: 360px;
}
.EmptyCoverButtonWrapper {
  max-width: 360px;
  width: 100%;
}
.EditBannerPhotoButton {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 9;
}
.EditCommonButtonFloatSecond {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 9;
  min-height: 34px;
}
.EditBannerPhotoButton:hover svg path,
.EditCommonButtonFloat:hover svg path,
.EditCommonButton:hover svg path,
.EditCommonButtonFloatSecond:hover svg path {
  stroke: #569ca4;
}
.EditCommonButton:hover {
  color: #569ca4;
}
.AddButtonBox {
  border-style: dashed;
  border-radius: 4px;
  border: 2px dashed #95c8c2;
  padding-top: 25px;
  padding-bottom: 25px;
}
.AddButtonBox:hover {
  border-width: 2px;
  border-style: dashed;
}
.AddLabel {
  margin-bottom: 15px;
}
.EmptyCoverButton {
  width: 100%;
  max-width: 360px;
}
.EditBannerPhotoButton,
.EmptyCoverButton {
  color: #fff;
  background-color: transparent;
  border-color: #fff;
}
.EditBannerPhoto {
  width: 100%;
  border-radius: 8px;
  background: linear-gradient(233deg, rgba(31, 28, 28, 0.7) 17.13%, rgba(73, 64, 64, 0) 63.93%);
}
.BoxHeadingContainer,
.ChatBoxContainer {
  position: relative;
}
.ChatBoxContainer {
  margin-top: 30px;
}
.EditCommonButtonFloat {
  border-color: #fff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
  position: absolute;
  z-index: 9;
  right: 10px;
  top: -5px;
  min-height: 34px;
}
.EditCommonButton {
  position: absolute;
  top: 4px;
  right: 0;
  font-weight: 700;
}
.EditCommonButton.EditCommonButtonSecond {
  top: 15px;
}
.EditCommonButton .MuiButton-label {
  text-transform: uppercase;
}
/* .EditBannerPhoto:hover {  background: rgba(0, 0, 0, 0.65);} */
.EditBannerPhotoButton {
  font-weight: 400;
}
.EmptyCoverButton .MuiSvgIcon-root {
  font-size: 24px;
}
.EmptyCoverHeading {
  font-family: Urbanist, sans-serif;
}
.MoreHorizIcon {
  cursor: pointer;
}
/* Invitation */
.DeclineInvitationTextarea {
  margin-top: 15px;
}
.InvitationTitleBox {
  text-align: center;
}
.InvitationTitle,
.InvitationSubTitle,
.InvitationName,
.DeclineInvitationSubTitle {
  text-align: center;
  margin-bottom: 10px;
}
.InvitationName,
.DeclineInvitationSubTitle {
  margin-bottom: 25px;
}
.InvitationContent {
  margin-top: 25px;
  margin-bottom: 30px;
}
.DeclineInvitationWrapper .InvitationContent {
  margin-bottom: 15px;
}
.InvitationSubTitle,
.DeclineInvitationSubTitle {
  font-weight: 400;
}
.InvitationContent li {
  margin-top: 10px;
  padding: 0px;
}
.InvitationHeading {
  margin-bottom: 20px;
}
.InvitationExpiredContent {
  margin-top: 20px;
}
.InvitationExpiredcontainer {
  padding-top: 150px;
}
.InvitationContent ul {
  list-style-type: disc;
}
.InvitationContent ul {
  padding-left: 15px;
  margin-top: 0px;
}
.InvitationContent ul ul {
  padding-left: 20px;
}
.InvitationWrapper .ButtonWrapper {
  margin-bottom: 10px;
  margin-top: 25px;
}
.ErrorMessage {
  color: red;
}
.SidebarContent {
  display: flex;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.16);
  padding: 20px 20px 0 20px;
  margin-right: 20px;
}
.FilterTagSecond.FilterTagContent {
  margin-bottom: 20px;
}
.GuideBannerBox {
  background-color: transparent;
}
.ClicksAwayImg {
  position: relative;
  top: 56px;
  left: -170px;
}
.FindingYourHomeImg {
  margin: 0 auto;
  display: table;
  position: relative;
  top: 35px;
}
.CommonBannerColumn {
  min-height: 640px;
}
.CommonBannerContent {
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.12);
  padding: 48px;
}
.CommonBannerBoxBG {
  width: 70%;
  min-height: calc(100% - 60px);
  position: absolute;
  z-index: 9;
  background-size: cover;
  border-radius: 0 8px 8px 0;
}
.CommonBannerBoxBG.RightBox {
  right: 0px;
  border-radius: 8px 0 0 8px;
}
.CommonBannerContainer {
  position: relative;
}
.CommonBannerWrapper {
  position: relative;
  z-index: 99;
}
.CommonBannerParagraph {
  margin-bottom: 30px;
}
.CommonBannerDivider {
  width: 240px;
  height: 4px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.CommonBannerUpperHeading {
  margin-bottom: 5px;
}
.LightBrownBox {
  border: 3px solid #dda091;
}
.LightBrownColor {
  color: #dda091;
}
.LightBrownBG {
  background-color: #dda091;
}
.LightGreenBox {
  border: 3px solid #569ca4;
}
.LightGreenColor {
  color: #569ca4;
}
.PageTitle {
  font-weight: 400;
}
.LightGreenBG {
  background-color: #569ca4;
}
.LightYellowBox {
  border: 3px solid #d4a569;
}
.LightYellowColor {
  color: #d4a569;
}
.LightYellowBG {
  background-color: #d4a569;
}
.CommonBannerContainer {
  padding-top: 30px;
  padding-bottom: 30px;
  background: #f6f7f8;
}
.CommonBannerContainer.FirstSection {
  margin-top: 30px;
}
.CommonBannerContainer.ThirdSection {
  margin-bottom: 30px;
}
.HomeBannerBox {
  display: flex;
  padding: 70px 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 720px;
  align-items: center;
}
.BannerHeadingTop {
  font-size: 36px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: left;
  color: #fff;
}
.BannerHeadingMiddel {
  font-size: 56px;
  font-weight: 400;
  line-height: 56px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 30px;
}
.BannerHeadingBottom {
  font-size: 136px;
  font-weight: 400;
  line-height: 136px;
  letter-spacing: 0.12em;
  text-align: left;
  color: #fff;
  font-family: 'Arima', cursive;
}
.BannerHeadingParagraph {
  font-size: 19px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: justify;
  color: #fff;
  margin-bottom: 30px;
}
.HomeBannerInfoText {
  width: 300px;
  padding: 30px 0 30px 0;
}
.ViewJobButton {
  padding-right: 15px;
  margin-top: 30px;
}
.FacilityColumn {
  margin-bottom: 15px;
}
.JobPostBox {
  display: flex;
  padding: 20px 20px 20px 20px;
  align-items: flex-start;
  border-radius: 15px;
  border: 1px solid #cbcedf;
  background: #fff;
  margin-top: 15px;
  justify-content: space-between;
  box-shadow: none;
}
.JobPostBox:hover,
.JobPostBox.Selected {
  cursor: pointer;
  border-color: #486877;
  background: #e5efeb;
}
.JobPostHeading {
  /* border-bottom: 1px solid transparent; */
  display: inline-block;
  color: #0d0c22;
  font-weight: 600;
}
.JobPostBox:hover .JobPostHeading,
.JobPostBox.Selected .JobPostHeading {
  /* border-color: #000; */
  text-decoration: underline;
}
.JobPostContent {
  width: calc(100% - 120px);
}
.JobPostLogo img {
  /* width: 70px; */
  /* margin-right: 15px; */
}
.JobPostIcon {
  margin-left: 10px;
  width: 110px;
}
.JobPostAddress,
.JobPostTiming {
  margin-top: 10px;
}
.JobPostTiming svg {
  margin-right: 5px;
}
.RadioOption,
.CheckboxOption {
  margin-top: 15px;
}
.CheckboxOption.CheckboxMT {
  margin-top: 5px;
}
.ResidentBoxCEV .CommonDetailBoxGrid {
  margin: 10px 24px 10px 0px;
}
.HeadingBoxCEV h4 {
  margin-right: 25px;
}
.ToggleButtonEmptyBox {
  display: flex;
}
.CommunityBadgeBox {
  display: flex;
  align-items: center;
}
.CommunityBadgeBox .BadgeBox {
  margin-right: 8px;
}
.BackButton {
  /* padding-left: 20px; */
  /* padding-right: 20px; */
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  position: sticky;
  top: 80px;
  height: 54px;
  z-index: 99;
  box-shadow: none;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.12); */
  border-radius: 0px;
  /* margin-top: -50px; */
  margin-bottom: 20px;
  cursor: pointer;
  font-weight: 700;
  font-size: 20px;
}
.BackButtonArrow {
  margin-right: 20px;
  color: rgba(0, 0, 0, 0.6);
}
.BackButtonLink {
  color: rgba(0, 0, 0, 0.87) !important;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  margin-left: 10px;
}
.SliderCarousel .CardBannerBox {
  /* height: 200px; */
  display: flex;
  height: 250px;
}
.AmenitiesCategory {
  font-weight: 600;
}
.SliderCarousel div[class*='Carousel-buttonWrapper'] .MuiButtonBase-root {
  top: calc(50% - 10px) !important;
  opacity: 0.6;
}
.SliderCarousel .CardBannerBox img {
  width: 100%;
  border-radius: 0px;
  object-fit: cover;
  object-position: center;
}
.CommonDetailBoxGrid {
  /* margin: 24px 24px 0 24px; */
}
.CommonDetailBoxGridSecond {
  /* margin: 0px 24px 0 24px; */
}
.UserInfoIP {
  margin: 20px 0 20px 0;
}
.ReadMoreBox span.show-more-less-clickable {
  display: block;
  margin-top: 10px;
  color: #d68649;
  text-decoration: none !important;
}
.ReadMoreBox span {
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  word-break: break-word;
  font-family: Urbanist, sans-serif;
  font-weight: 600;
  line-height: 22px;
}
.ChatText {
  margin-bottom: 20px;
  line-height: 24px;
  white-space: 'pre-line';
}
.ChatText,
.ChatTextLabel {
  color: rgba(0, 0, 0, 0.87);
}
.ChatBox .UserInfoIP {
  margin-bottom: 0px;
}
.FilterTag.Selected {
  font-weight: 500;
}
.ChatContent p:last-child {
  margin-bottom: 0px;
}
video::-webkit-media-controls-panel {
  display: none !important;
  opacity: 1 !important;
}
.MapImg {
  margin-top: 24px;
  min-height: 340px;
}
.ChatContent {
  background: #e5efeb;
  min-height: 50px;
  border-radius: 8px;
  margin-top: 20px;
  position: relative;
  padding: 20px;
}
.ChatContent::after {
  content: '';
  display: inline-block;
  width: 16px;
  height: 15px;
  margin-left: 5px;
  position: absolute;
  bottom: -8px;
  left: 15px;
  background: url(../../assets/images/BottomLeftArrow.svg) no-repeat left center;
}
.UserInfoIP .FirstName {
  margin-right: 5px;
}
.UserInfoIP .UserInfoText {
  margin-left: 10px;
}
.ReadMoreBox span.show-more-less-clickable {
  display: block;
  margin-top: 10px;
  color: #486877;
  text-decoration: none !important;
  font-weight: 500;
}
.ReadMoreBox span.show-more-less-clickable:hover {
  color: #569ca4;
}
.show-more-less-clickable::after {
  content: '';
  display: inline-block;
  width: 10px;
  height: 7px;
  margin-left: 5px;
  background: url(../../assets/images/ArrowDown.svg) no-repeat left center;
}
.show-more-less-clickable.expanded::after {
  transform: rotate(180deg);
}
/* .show-more-less-clickable:hover::after {
  background: url(../../assets/images/ArrowDownHover.svg) no-repeat left center;
} */
.CommonDetailBoxGridSlider {
  /* margin: 14px 14px 0 14px; */
  margin-bottom: 15px;
}
.ViewButton {
  margin: 10px 14px 0 14px;
}
.AmenitiesSliderBox {
  /* margin: 10px 10px 15px 10px; */
  background-color: #fff;
}
.SliderContainer {
  position: relative;
}
.AmenitiesCategory {
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.6);
  text-transform: uppercase;
}
.AmenitiesName {
  margin-top: 0px;
  font-weight: 600;
}
.AmenitiesInsight {
  margin-top: 8px;
  max-height: 70px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.BadgeHeadingCD {
  margin-left: 10px;
}
.HeadingBoxRC {
  margin-top: 25px;
}
.HeadingRC {
  /* margin-bottom: 10px; */
}
.HeadingBoxRC .MuiTypography-body2 {
  margin-bottom: 5px;
}
.UploadPhotoBox {
  padding: 16px;
}
.NoteInfoText {
  font-size: 12px;
  font-family: 'Open Sans', serif;
}
.HomeIcon {
  margin-top: 1px;
  height: 32px;
}
.CountIcon {
  margin-top: 3px;
  font-size: 28px;
}
.CountContent {
  margin-left: 8px;
}
.Count {
  font-weight: 500;
}
.CountBox {
  display: flex;
}
.ImageSliderBox {
  position: relative;
}
.CityNameSubheading {
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  margin-top: 8px;
}
.SlideCol {
  width: 100%;
}
.SlideCol img {
  object-fit: cover;
  height: 200px;
}
/* .SlideImgBox {  background-size: cover;  width: 100%;  min-height: 300px;  min-width: 300px;  background-position: center;} */
.CommunityCityBox .CityNameHeading {
  margin-bottom: 0px;
  margin-right: 10px;
}
.CommunityCityBox {
  display: flex;
  align-content: center;
  margin-bottom: 20px;
}
.CityNameHeading {
  text-transform: capitalize;
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.87);
}
.StateNameHeading {
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.6);
  /* font-size: 12px; */
  font-weight: 500;
}
.communityDetailsContainer .JobListColumn {
  max-width: 100%;
  flex-basis: 100%;
}
.HeadingBoxCEV {
  justify-content: space-between;
}
.HeadingCEV {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 10px;
}
.HeadingCEVCaption {
  font-weight: 700;
  margin-bottom: 10px;
}
.EmptySearchBox {
  text-align: center;
  padding: 20px 20px 20px 20px;
  border-radius: 15px;
  /* border: 1px solid #cbcedf; */
  /* background: #fff; */
  margin-top: 20px;
  min-height: 280px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  background: url(../../assets/images/NotFound-Bg.svg) no-repeat center center;
}
.EmptySearchBox.BgSecond {
  background: url(../../assets/images/NotFound-Bg-2.png) no-repeat center center;
  background-size: contain;
}
.EmptySearchBox.BgThird {
  background: url(../../assets/images/NotFound-Bg-3.png) no-repeat center center;
  background-size: contain;
}
.EmptySearchBox.BgFourth {
  background: url(../../assets/images/NotFound-Bg-4.svg) no-repeat center center;
  background-size: contain;
}
.EmptySearchHeading {
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 10px;
}
.EmptySearchSubHeading {
  color: rgba(0, 0, 0, 0.6);
  max-width: 360px;
}
.communityDetailsContainer .JobListContainer {
  padding-left: 0px;
  padding-right: 0px;
  /* margin-left: -15px; */
  margin-bottom: 15px;
}
.communityDetailsContainer .job-inner-list.container {
  padding: 0;
}
.communityDetailsContainer .CDTotal {
  margin-right: 50px;
}
.communityDetailsContainer .CDNumber,
.CommunityEditViewResidents .CDNumber,
.CommunityEditViewJobs .CDNumber,
.CommunityEditViewCostOfLiving .CDNumber {
  font-family: Urbanist, sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
  /* font-size: 20px; */
  color: rgba(0, 0, 0, 0.87);
}
.JobPostLogo .MuiAvatar-root {
  border-radius: 6px;
  width: 56px;
  height: 56px;
  color: #486877;
}
.communityDetailsContainer .JobListContainer .PageSpacing {
  padding: 0;
}
.communityDetailsContainer .JobListContainer .jobListMainHeading {
  display: none;
}
.JobTextPara {
  margin-bottom: 0px;
  margin-top: 0px;
  padding-left: 15px;
}
.JobPostLogo .MuiAvatar-root {
  margin-right: 15px;
}
.communityDetailsContainer .CountBox {
  margin-right: 16px;
}
.CommunityListPage .CountBox {
  /* margin-right: 8px; */
}
.communityDetailsContainer .ScrollerBoxCD .CommonCardBanner,
.communityDetailsContainer .ScrollerBoxCD .CommonCardImgBox {
  display: none;
}
.communityDetailsContent {
  position: relative;
  z-index: 999;
}
.SidebarContent ul li {
  list-style: none;
  margin-bottom: 20px;
}
.SidebarContent ul li a {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  font-size: 18px;
  padding-left: 15px;
  display: flex;
}
.SidebarContent ul li a.active {
  color: #486877;
}
.SidebarContent ul {
  margin: 0;
  padding: 0;
  width: 100%;
}
.communityDetailsBannerbg {
  min-height: 350px;
  width: 100%;
  background-size: contain;
  background-repeat: repeat;
  position: absolute;
  top: 80px;
  left: 0;
  z-index: 99;
}
.communityDetailsSidebar {
  position: sticky;
  height: 420px;
  top: 100px;
  margin-top: 420px;
}
.CommunityCoverPhotoModule .CardBannerSection {
  margin-bottom: 30px;
}
.CardBannerSection {
  margin-bottom: 20px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  display: flex;
  background: transparent;
  border-radius: 8px;
  height: 500px;
  background-size: cover;
  background-position: center;
  background-color: #fff;
  position: relative;
}
.CardBannerSection img {
  width: 100%;
  border-radius: 8px;
}
.CommonCardBanner,
.CommonCardImgBox {
  box-shadow: none;
  width: 100%;
  height: auto;
  min-height: 200px;
  position: relative;
  cursor: pointer;
}
.CommonSelect {
  margin-bottom: 8px;
}
.CommonEdit {
  font-weight: 700;
  cursor: pointer;
  color: #486877;
  /* border-bottom: 1px solid transparent; */
}
.CommonEdit:hover {
  color: #569ca4;
  /* border-bottom: 1px solid transparent; */
}
.UserInfoName,
.EditUserInfoName,
.ToggleButtonBox {
  display: flex;
  align-items: center;
}
.ToggleButtonBox {
  justify-content: flex-end;
}
.UserInfoText {
  margin-left: 16px;
}
.FirstName,
.LastName {
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.87);
  display: inline-block;
  margin-right: 5px;
}
.EditUserInfoText .FirstName,
.EditUserInfoText .LastName {
  color: rgba(0, 0, 0, 0.6);
}
.CommonInfoField {
  margin-bottom: 16px;
}
.CommonCard {
  border-radius: 8px 8px 16px 16px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  margin: 0 10px 20px 10px;
  height: calc(100% - 20px);
  cursor: pointer;
}
.ResultNumber {
  padding-left: 10px;
  padding-right: 10px;
}
.SearchBoxContainer {
  justify-content: space-between;
}
.FavoriteToggleButton {
  padding: 0;
  border: none;
}
.FavoriteToggleButton:hover {
  background-color: transparent;
  box-shadow: none;
}
.FavoriteToggleButton.Mui-selected:hover {
  background-color: transparent;
}
.FavoriteToggleButton.Mui-selected {
  background-color: transparent;
}
.FavoriteToggleButton svg path:first-child {
  opacity: 0;
}
.FavoriteToggleButton.Mui-selected svg path:first-child {
  opacity: 0.5;
}
.RedLineButton {
  color: #c72943;
  padding: 0;
  min-width: 0;
  min-height: 0;
  border-bottom: 1px solid #c72943;
  border-radius: 0;
  font-weight: 500;
}
.DeleteAmenityButton {
  letter-spacing: 2px;
}
.DeleteAmenityButton,
.LinkTypeButton.DeleteAmenityButton .MuiButton-label {
  border: none !important;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 10px;
}
.RedLineButton:hover {
  color: #e57184;
  background-color: transparent;
  box-shadow: none;
  border-bottom: 1px solid transparent;
}
.LinkButton {
  padding: 0;
  min-width: 0;
  min-height: 0;
  border-bottom: none;
  border-radius: 0;
}
.LinkButton:hover {
  background-color: transparent;
  box-shadow: none;
}
.LinkButton svg {
  color: #486877;
}
.Input {
  color: white;
  visibility: hidden;
  width: 0px;
}
.TextField {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 0;
  margin-top: 0;
  font-weight: 500;
}
.CloseButton {
  right: 8px;
  top: 8px;
  text-align: right;
}
.MuiAvatar-colorDefault {
  background-color: #e2e1d5;
}
.ShareDialog {
  padding: 8px 16px 16px;
  max-width: 375px;
}
.ShareIcon {
  border: 1px solid #e2e1d5;
  border-radius: 100%;
  height: 50px;
  width: 50px;
  padding: 15px;
  margin: auto;
  cursor: pointer;
}
.copyTitle {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  margin-top: 15px;
}
.ShareCloseBtn {
  margin-right: -10px;
}
.ShareHeading {
  margin: 15px 0 30px 0;
}
.ShareTitle {
  color: rgba(0, 0, 0, 0.87);
}
.ShareDivider {
  background: #e1e4e8;
}
.ShareContentBox {
  margin-bottom: 16px;
  display: flex;
  padding: 0 10px;
}
.ShareContentBox .ShareContent {
  margin: auto;
}
.ShareContentBox .ShareContent:first-child {
  margin: 0;
}
.ShareContentBox .ShareContent:last-child {
  margin: 0;
}
.ShareFormContainer {
  width: 375px;
}
.TextArea {
  font-family: inherit;
  margin: 0;
  font: inherit;
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  display: block;
  padding: 10px;
  font-size: 16px;
  background: none;
  font-weight: 500;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  background-color: #fff;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
}
.TextArea:hover,
.TextArea:focus {
  border-width: 1px;
}
.SendEmailButton {
  min-width: 75px !important;
  padding: 10px 16px;
  border-radius: 6px;
  margin-left: 30px !important;
}
.ShareFormContainer ::placeholder {
  color: #c8c8c8;
  opacity: 1;
  /* Firefox */
}
.ButtonContainer {
  display: inline-flex;
  padding-bottom: 10px;
  padding-top: 25px;
}
.IconTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.6);
  padding-top: 6px;
}
.HalfwidthBox {
  width: 47%;
}
.DashIcon {
  width: 6%;
  text-align: center;
}
.CommonFilterBox {
  max-width: 475px;
  max-height: 585px;
  position: relative;
  background: transparent;
  box-shadow: none;
}
.FilterHeader {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: calc(100% - 0px);
  top: 10px;
  min-height: 40px;
  align-items: center;
  left: 0;
  background-color: #fff;
  z-index: 998;
  border-bottom: 1px solid #e2e1d5;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
}
.FilterFooter {
  position: absolute;
  width: calc(100% - 0px);
  bottom: 10px;
  min-height: 40px;
  left: 0px;
  background-color: #fff;
}
.FilterBody {
  padding-left: 20px;
  padding-right: 20px;
  height: 500px;
  overflow: auto;
  margin-top: 30px;
  padding-bottom: 90px;
}
.FilterTagBox {
  margin-top: 20px;
}
.CommonFilterBox .FilterTagBox {
  margin-top: 30px;
}
.FilterBackButton {
  display: flex;
  align-items: center;
}
.FilterTagSubHeading {
  margin-top: 10px;
}
.FilterTagInner {
  margin-top: 25px;
}
.FilterTagInner .InputLabelFld {
  margin-bottom: 0;
}
.FilterTagInner .MuiFormControlLabel-root {
  margin-top: 6px;
}
.FilterTag {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  min-width: auto;
  margin-right: 15px;
  border: 1px solid #fff;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 15px;
  font-weight: 500;
  text-align: left;
  line-height: 20px;
  font-size: 16px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
}
/* .FilterTag:hover, */
.FilterTag.Selected,
.FilterTagSecond .FilterTag:hover,
.FilterTagSecond .FilterTag.Selected {
  background: #e5efeb;
  border: 1px solid #486877;
  color: #486877;
}
.FilterTagSecond .FilterTag {
  background: rgba(241, 243, 249, 1);
  border-color: rgba(241, 243, 249, 1);
}
.FilterAction {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
.FilterCount {
  margin-left: 4px;
}
.AccountActionButton .RedLineButton:first-child {
  margin-right: 25px;
}
.communityDetailsContainer .CommonCardColumn .MuiCardContent-root {
  padding: 30px;
}
.CommonCardColumn .BadgeHeading {
  display: none;
}
.CommonCardColumn .ToggleButtonEmptyBox .BadgeBox {
  display: none;
}
.communityDetailsContainer .ScrollerBoxCD .CommonCardColumn .ToggleButtonEmptyBox .BadgeBox {
  display: flex;
}
.communityDetailsContainer .ScrollerBoxCD .recommended {
  background: #d4a569;
  color: #fff;
  padding: 4px 6px;
  display: flex;
  width: 175px;
  border-radius: 4px;
}
.communityDetailsContainer .ScrollerBoxCD .represented {
  /* background: #486877; */
  /* padding: 4px 6px; */
  /* color: #fff; */
  display: flex;
  /* width: 240px; */
  margin-right: 10px;
  /* border-radius: 4px; */
}
.communityDetailsContainer .StateNameHeading {
  margin-bottom: 10px;
}
.recommended {
  /* top: 20px;      right: 20px; */
  width: auto;
  /* position: absolute; */
  /* background: #D4A569; */
  display: flex;
  align-items: center;
  /* padding: 4px 6px; */
  color: #569ca4;
}
.AboutVideoBox video {
  cursor: pointer;
}
.CommonBadgeInner {
  display: flex;
  /* justify-content: end; */
  align-content: center;
  /* margin-left: 0px; */
}
.BadgeIcon {
  width: 22px;
  height: 22px;
}
.BadgeHeading {
  color: #fff;
  margin-left: 5px;
}
.communityDetailsContainer .ScrollerBoxCD .ToggleButtonBox {
  position: absolute;
  right: 30px;
  top: 30px;
}
.ProfileTrackerPerBox {
  margin-bottom: 10px;
}
.ProfileProgress {
  height: 16px;
  border-radius: 10px;
  background-color: #f8f9fc;
}
.ProfileProgress .MuiLinearProgress-bar {
  background-color: #569ca4;
}
.ShowDetails {
  font-weight: 700;
  position: relative;
  margin-top: 15px;
  cursor: pointer;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.ShowDetailsIcon {
  position: absolute;
  top: 1px;
  margin-left: 5px;
}
.ProfileTitle {
  color: rgba(0, 0, 0, 0.6);
}
.ProfileTrackerContainer .MuiFormControlLabel-label {
  font-size: 14px;
}
.ProfileTrackerContainer .MuiCheckbox-colorPrimary.Mui-checked,
span.ProfileTrackerCheckbox.Mui-checked + span.MuiFormControlLabel-label {
  color: #919d86;
}
.ProfileTrackerContainer .MuiFormControlLabel-root {
  margin-bottom: 5px;
  cursor: initial;
}
.ProfileTrackerContainer .MuiFormControlLabel-root .MuiCheckbox-root {
  cursor: initial;
}
.ProfileTitleDesc {
  /* color: rgba(0, 0, 0, 0.6);  font-size: 12px;  font-style: normal;  font-weight: 400;  line-height: 14px;  */
  margin-top: 20px;
}
.ProfileHead {
  margin-top: 20px;
  margin-bottom: 5px;
}
.DividerCD {
  margin-top: 30px;
  margin-bottom: 0px;
}
.DividerTB {
  margin-top: 30px;
  margin-bottom: 30px;
}
.AddButton {
  margin-left: 5px;
}
.InsightLabel {
  padding: 0 16px;
  margin-top: 30px;
}
.InsightButtonContainer .MuiButton-fullWidth {
  width: calc(100% - 78px);
  margin-left: auto;
  margin-right: auto;
}
.InsightButtonContainer {
  margin: 24px 0px 4px 0px;
}
/* .InsightButtonContainer button {  border-radius: 6px;  padding: 0px 20px;  font-size: 16px;  font-style: normal;  font-weight: 700;  line-height: 24px;} */
/* .InsightButtonContainer img {  padding-right: 6px;} */
.ViewPublicBox {
  background-color: #fff;
}
.ActionButtonPopup .MuiPaper-root.MuiMenu-paper {
  border: 1px solid #e2e1d5;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.16);
  min-width: 210px;
}
.LeaveCommunity,
.CommonListDivider {
  border-top: 1px solid #e2e1d5;
}
.TableActionPopover .ActionButtonContainer {
  font-size: 14px;
  color: #486877;
}
.ActionButtonContainer {
  color: #000000de;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  padding: 10px;
  white-space: normal;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}
.ActionButtonContainer.LeaveCommunity {
  color: #e53451;
}
.ActionButtonIcon {
  margin-right: 10px;
}
.ClicksAwayImgMobile,
.GuideYouHomeImgMobile,
.FindingYourHomeImgMobile {
  display: none;
}
.ClicksAwayImg,
.GuideYouHomeImg,
.FindingYourHomeImg {
  display: block;
}
.CommonOption .RadioOption,
.CommonOption .CheckboxOption {
  padding-left: 15px;
}
/* .DeclineInvitationTopsection {  display: none;} */
.CommonTabpanel .CommonTabBox {
  padding: 0px 0px 0px 10px;
}
.UserInfoButtonBox {
  margin-left: 80px;
  margin-top: 10px;
}
.UserInfoButtonBox .MuiButtonBase-root {
  margin-top: 15px;
  font-size: 16px;
}
.UserTableName,
.CommunityTableName {
  font-weight: 600;
}
.CommunityTableName,
.UserTableRole {
  white-space: nowrap;
}
.CommunityTableName {
  text-transform: capitalize;
}
.CommunityTableEmail,
.CommunityTableState,
.UserTableEmail,
.UserTableRole {
  margin-top: 5px;
}
.RadioCationBox {
  margin-left: 32px;
  margin-bottom: 20px;
  margin-top: -5px;
}
.ActionMaterialTable th.MuiTableCell-head:last-child {
  font-size: 0px !important;
}
.AdminInvitationsTableContainer th.MuiTableCell-head:first-child {
  min-width: 200px;
}
.AdminUserTableContainer th.MuiTableCell-head:first-child,
.AdminUserTableContainer td.MuiTableCell-body:first-child {
  min-width: 300px;
}
.AdminUserTableContainer th.MuiTableCell-head:nth-child(5),
.AdminUserTableContainer td.MuiTableCell-body:nth-child(5) {
  width: 100px !important;
}
.ActionMaterialTable td.MuiTableCell-body:last-child div {
  justify-content: center;
}
/* .ChangeAccountTypeContainer .AccountInfoTab, .ChangeAccountTypeContainer .UserInfoButtonBox {  display: none;} */
.ChangeAccountTypeContainer .PageSpacing {
  padding-top: 25px;
  padding-bottom: 35px;
}
.CoverImageBox {
  position: relative;
}
.CoverImg {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
}
.CoverImageDeleteBox {
  position: absolute;
  top: 10px;
  right: 10px;
}
.AboutCommunityVideoContainer video {
  border-radius: 8px;
}
.CommonStatus {
  border-radius: 28px;
  padding: 3px 8px;
  display: inline-block;
  white-space: nowrap;
}
.Accepted,
.Represented {
  background-color: #e5efeb;
  color: #486877;
}
.Declined,
.Unrepresented {
  background-color: #ffebec;
  color: #c72943;
}
.Pending,
.RepInvited {
  background-color: #f8f9fc;
  color: rgba(0, 0, 0, 0.6);
}
.Expired {
  background-color: rgba(226, 225, 213, 0.3);
  color: rgba(0, 0, 0, 0.6);
}
.InvitationStatusInfo {
  text-align: center;
  margin-right: 30px;
  margin-top: 20px;
}
.InvitationStatusInfo .MuiTypography-body2 {
  font-weight: 600;
}
.InvitationStatusInfoContainer {
  margin-top: 10px;
  margin-bottom: 40px;
}
.InviteCommunityRepresentativeContainer .CommonOption {
  margin-top: 20px;
  padding-left: 0px;
  padding-right: 25px;
}
.AboutCommunityVideoContainer {
  margin-top: 20px;
}
.SearchBoxIcon button {
  border: 1px solid #e2e1d5;
  position: relative;
  border-radius: 100%;
  background-color: #fff !important;
  padding: 8px;
  width: 42px;
}
.SearchBoxIcon button svg {
  width: 20px;
}
.MobileSearchBox {
  display: none;
}
.InvitationInfo {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.InvitationInfoLabel {
  font-weight: 600;
  min-width: 130px;
}
.InvitationInfoData {
  text-align: right;
}
.InvitationInfoData.Name {
  text-transform: capitalize;
}
.DeclineClick {
  cursor: pointer;
  text-decoration: underline;
}
.InvitationButtonBox {
  display: flex;
  justify-content: flex-end;
}
.FullWidthButton {
  width: 100% !important;
}
.ReportLink {
  display: flex;
  align-items: center;
  margin-top: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.ReportHeading {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.ReportHeading svg {
  margin-right: 10px;
}
.ReportLink svg {
  margin-left: 10px;
}
.AdminReportBox {
  margin-bottom: 50px;
}
.CommunityReportingFilter {
  justify-content: space-between;
  display: flex;
}
.CommunityReportingFilter .SearchButton {
  max-height: 44px;
}
.FilterTime {
  min-width: 240px;
}
.FilterCommonTime {
  padding: 10px 20px;
  cursor: pointer;
}
.FilterCommonTime:hover,
.FilterCommonTime.Active {
  background-color: #fff2e0;
}
.ExportIconBox {
  margin-left: 10px;
}
.apexcharts-toolbar {
  display: none !important;
}
.apexcharts-legend {
  padding: 0 !important;
  margin-left: -6px;
}
.apexcharts-legend .apexcharts-legend-series {
  margin-right: 20px !important;
}
.BarChartBox {
  margin-bottom: 20px;
  margin-left: -18px;
}
.apexcharts-legend.apexcharts-align-right .apexcharts-legend-series,
.apexcharts-legend.apexcharts-align-left .apexcharts-legend-series {
  display: flex !important;
}
.DeclineLabel {
  color: rgba(0, 0, 0, 0.6);
  font-size: 18px;
  font-weight: 400;
  margin-top: 24px;
}
.DeclineBackButton {
  color: #606b8a;
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1500px) {
  .CommonBannerBoxBG {
    width: 75%;
  }
}
@media screen and (min-device-width: 1025px) {
  .BarChartBox {
    width: 80%;
  }
}
@media screen and (max-device-width: 1024px) {
  /* .DeclineInvitationTopsection {    display: block;  } */
}
@media screen and (max-device-width: 799px) {
  .ResidentsModule .CDTotal {
    margin-right: 0px;
  }
}
@media screen and (max-device-width: 899px) {
  .JobDetailsContent {
    font-size: 16px;
    line-height: 24px;
  }
  .JobDetailsApply {
    width: 100%;
  }
  .CommonBannerColumn {
    min-height: 530px;
  }
  .CommonBannerWrapper {
    padding: 0;
  }
  .CommonBannerBoxBG {
    display: none;
  }
  .CommonBannerContainer {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .CommonBannerContainer.FirstSection {
    margin-top: 0px;
  }
  .CommonBannerContainer.SecondSection {
    margin-top: 30px;
  }
  .CommonBannerContainer.ThirdSection {
    margin-top: 30px;
    margin-bottom: 40px;
  }
  .RightImgColumn {
    flex-direction: column-reverse;
  }
  .ClicksAwayImgMobile,
  .GuideYouHomeImgMobile,
  .FindingYourHomeImgMobile {
    display: block;
  }
  .ClicksAwayImg,
  .GuideYouHomeImg,
  .FindingYourHomeImg {
    display: none;
  }
  .CommonBannerContent {
    padding: 20px 20px;
  }
  .CommonBannerContentWrapper {
    margin: -27% 20px 0 20px;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 899px) {
  .communityDetailsContainer .JobListColumn {
    min-height: 390px;
  }
}
@media screen and (min-device-width: 900px) {
  .RejectViewButton {
    min-width: 340px;
  }
  .JobDetailsApplyWrapper {
    position: sticky;
    top: 130px;
    z-index: 999;
    padding-bottom: 300px;
  }
  .communityDetailsContainer .JobListColumn {
    min-height: 363px;
  }
}
@media screen and (min-device-width: 801px) {
  .FilterBackButton .BackButtonArrow {
    display: none;
  }
  .CommonFilterBox {
    padding: 20px 0px;
  }
}
@media screen and (min-device-width: 800px) {
  .communityDetailsContainer .AutoWidth {
    max-width: initial;
    flex-basis: auto;
    /* margin-right: 10px; */
    /* min-width: 170px; */
  }
}
@media screen and (max-device-width: 800px) {
  .FilterBody {
    height: calc(100vh - 40px);
  }
  .CommonFilterBox {
    max-height: 100%;
    max-width: 100%;
  }
  .FilterAction .MuiButton-root {
    padding: 5px 15px;
  }
  .FilterBody {
    padding-bottom: 250px;
    margin-top: 0px;
  }
  .FilterAction {
    padding-bottom: 100px;
  }
  .FilterFooter {
    bottom: 0px;
  }
  .FilterHeader {
    position: sticky;
    width: calc(100% - 0px);
    top: 0;
    padding-top: 10px;
  }
}
@media screen and (max-device-width: 900px) {
  .JobListContainer .JobListColumn a:first-child .JobPostBox {
    margin-top: 0px;
  }
}
@media screen and (max-device-width: 992px) {
  .MobileFilterBox {
    display: flex;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 25px;
  }
  .SidebarContent ul li a {
    font-size: 16px;
  }
  .AmenityFiltersContainer {
    display: none;
  }
  .ViewAmenitiesContent .ViewAmenityGrid {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
}
@media screen and (min-device-width: 768px) {
  .ClimateTypeSection,
  .GettingAroundTypeSection,
  .CostOfLivingTypeSection {
    padding-left: 20px;
  }
  .CommonFilterBox {
    min-width: 475px;
  }
  .CommunityRepProfileUser {
    padding-left: 15px;
  }
  .DataCDNumber {
    padding-left: 45px;
  }
  .CommonOption {
    padding-left: 25px;
  }
  .DropzoneNote {
    display: inline-block;
    vertical-align: top;
    margin: 0 20px;
  }
  .DropzoneSuccess {
    width: 100%;
  }
  .VideoSuccess {
    margin-right: 10px;
  }
}
@media screen and (max-device-width: 600px) {
  .PaginationNumber {
    margin-right: 0;
    display: block;
    text-align: center;
    margin-bottom: 20px;
  }
  .PaginationWrapper.BasicPagination {
    flex-direction: column;
  }
}
@media screen and (max-device-width: 380px) {
  .MuiPagination-root ul li {
    margin-bottom: 8px;
  }
}
@media screen and (max-device-width: 340px) {
  .SlideSection {
    min-width: 260px !important;
  }
}
@media screen and (max-device-width: 600px) {
  .CommonBannerButton {
    width: 100%;
  }
  .CommonBannerDivider {
    width: 140px;
  }
  .CardBannerSection {
    display: block !important;
  }
}
@media screen and (max-device-width: 767px) {
  .ViewHomesButton {
    display: block;
  }
  .DialogActionsWrap {
    flex-wrap: wrap;
  }
  .DialogActionsWrap .NoBorderButton {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .CommunityReportingFilter {
    display: block;
  }
  .ReportMobileFilter {
    display: block;
  }
  .InvitationStatusInfo .MuiTypography-caption {
    text-align: left;
  }
  .MuiTypography-root.MuiTypography-caption br {
    display: none;
  }
  .InvitationStatusInfo .MuiTypography-body2,
  .CommunityTableName,
  .UserTableName,
  .CommunityTableName {
    font-weight: 700;
  }
  .InvitationStatusInfo {
    margin-right: 0px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
  .CommonTabpanel .CommonTabBox {
    padding: 0px 0px 0px 0px;
  }
  .InvitationStatusInfoContainer .FlexCenterStart {
    display: block;
  }
  .MobileSearchTextBox {
    width: 100%;
  }
  .MobileSearchTextBox .SearchBox {
    max-width: 100% !important;
    width: 100% !important;
    margin-right: 0px;
  }
  .MobileSearchTextBox .SearchBox {
    margin-top: 0px;
  }
  .TableSearchBoxContainer {
    flex-wrap: wrap;
  }
  .MobileSearchBox {
    display: block;
  }
  .TableSearchBoxContainer .DesktopSearchBox,
  .HideOnMobile {
    display: none;
  }
  .TableSearchBoxContainer .AutocompleteBox {
    margin-bottom: 20px;
    margin-top: 20px;
    width: 85%;
  }
  .InviteCommunityRepresentativeContainer .CommonOption {
    padding-left: 0px;
    padding-right: 0px;
  }
  .CommonButtonTypeTab .MuiTabs-flexContainer {
    width: 100%;
  }
  .CommonButtonTypeTab .MuiTab-textColorInherit {
    min-width: 50% !important;
  }
  .AdministrativeCenterContainer .SearchBoxContainer {
    display: block;
  }
  .AdministrativeCenterContainer .SearchBox {
    width: calc(100% - 0px);
    max-width: 100%;
    margin-right: 0px;
  }
  .AdministrativeCenterContainer .MaterialTableContainer {
    margin-top: 20px;
  }
  .ChangeAccountTypeContainer .AccountUserInfoTab {
    padding-left: 0px;
  }
  .BackButtonWrapper {
    display: block;
  }
  .MuiSwitch-root.ShowHideButton .MuiSwitch-switchBase::before {
    font-size: 16px;
  }
  /* .VideoSuccessInfo {      width: calc(90% - 0px);    } */
  .FullWidthPattern .MuiContainer-root {
    padding: 0px;
  }
  .InvitationSubTitle,
  .InvitationName {
    display: inline;
  }
  .InvitationName {
    margin-left: 8px;
  }
  .InvitationWrapper {
    border-radius: 0px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .FullWidthPattern .PageSpacing {
    padding-top: 0;
    padding-bottom: 0;
  }
  .MobileFacilityList::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .MobileFacilityList {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
  .DesktopFilter {
    display: none;
  }
  .MobileFacilityList {
    overflow: scroll;
    display: block;
    padding-bottom: 15px;
  }
  .MobileFacilityList .FilterTagContent {
    display: flex;
    width: 200%;
  }
  .MobileFacilityList .FilterTagContent .FilterTag {
    white-space: nowrap;
  }
  .BackButton {
    font-size: 18px;
  }
  /* .EmptySearchBox {    min-height: 160px;  } */
  .ViewAmenitiesContent .AddAmenityContentBox {
    padding-left: 15px;
    padding-right: 15px;
  }
  .ViewAmenitiesContent .AddAmenityDividerRow {
    padding-left: 0px;
    padding-right: 0px;
  }
  .ViewAmenitiesContent .BackButton {
    margin-left: 0;
    margin-right: 0;
  }
  .EditViewButton,
  .EditViewButtonText {
    max-width: 100%;
  }
  .AmenityImagesContent div.AmenityImagesBox:first-child {
    min-height: 228px;
    max-height: 228px;
  }
  .AmenityImagesContent div.AmenityImagesBox:first-child .AmenityImg {
    min-height: 228px;
    max-height: 228px;
  }
  .AmenityImagesContent .AmenityImagesBox,
  .AmenityImagesBox + .AmenityImagesUploadBox {
    max-height: 106px;
    min-height: 106px;
  }
  .AmenityImagesContent .AmenityImagesBox .AmenityImg {
    height: 106px;
  }
  .AmenityImagesContent {
    grid-template-columns: 31.333333% 31.333333% 31.333333%;
  }
  .ActionButtonContainer {
    font-size: 14px;
  }
  .FilterTag {
    font-size: 13px;
    min-height: 40px;
  }
  /* .ViewButton {    margin-left: 0px;    margin-right: 0px;  } */
  .CommonImg.MapImg {
    margin: 0px;
    min-height: auto;
  }
  .InsightButtonContainer .MuiButton-fullWidth {
    width: calc(100% - 0px);
  }
  .InsightLabel {
    padding: 0px;
  }
  .communityDetailsContainer .CommonCardColumn .MuiCardContent-root,
  .EmptyCardBannerOverlay {
    padding: 16px;
  }
  .CardBannerSection.EmptyCardBannerSection {
    display: block;
    border-radius: 0px;
    height: 200px;
  }
  .EmptyCoverHeading,
  .EmptyCoverSubHeading {
    font-weight: 400;
  }
  .EmptyCardBannerOverlay {
    border-radius: 0px;
  }
  .ProfileTrackerContainer .MuiFormControlLabel-label {
    font-size: 14px;
  }
  .HeadingBoxCEV h4 {
    margin-right: 5px;
  }
  .HeadingBoxCEV {
    justify-content: space-between;
  }
  .BackButton {
    padding-left: 12px;
    margin-top: -20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    margin-left: -16px;
    margin-right: -16px;
  }
  .communityDetailsContainer .ScrollerBoxCD .ToggleButtonBox {
    /* position: relative; */
    right: auto;
    top: auto;
    transform: translate(0%, 0%);
  }
  .communityDetailsContainer .ScrollerBoxCD .CommonCardBanner,
  .communityDetailsContainer .ScrollerBoxCD .CommonCardImgBox {
    display: block;
  }
  .communityDetailsBannerbg,
  .communityDetailsSidebar,
  .CardBannerSection {
    display: none;
  }
  /* .ShareContentBox {
    justify-content: space-between;
  }
  .ShareContentBox .ShareContent {
    margin-right: 0;
  } */
  .CommonCard {
    margin: 0 0px 30px 0;
  }
  .BackButton {
    /* margin-left: -16px;    margin-right: -16px; */
  }
  .MapImg {
    margin-left: 24px;
    margin-right: 24px;
  }
  /* Page setting */
  .communityDetailsContainer {
    /* margin: 0px -16px 0 -16px; */
  }
  /* Page setting */
  .ViewPublicBox {
    padding-left: 16px;
    padding-right: 16px;
  }
  .BannerHeadingTop {
    font-size: 21px;
    line-height: 21px;
  }
  .BannerHeadingMiddel {
    font-size: 32px;
    line-height: 32px;
  }
  .BannerHeadingBottom {
    font-size: 80px;
    line-height: 80px;
  }
  .BannerHeadingParagraph {
    font-size: 16px;
    line-height: 24px;
  }
  .BannerButton {
    width: auto;
    font-size: 17px;
  }
  .HomeBannerBox {
    padding: 40px 0;
    min-height: 420px;
  }
  .JobPostBox {
    padding: 12px;
    flex-wrap: wrap;
  }
  .JobPostLogo .MuiAvatar-root {
    margin-right: 10px;
  }
  .JobPostLogo .MuiAvatar-root {
    width: 40px;
    height: 40px;
  }
  .JobPostContent {
    width: calc(100% - 50px);
  }
  .JobPostIcon {
    margin-left: 48px;
    width: 100%;
  }
  .JobTextPara {
    padding-left: 0px;
  }
  .JobPostAddress,
  .JobPostTiming {
    margin-top: 8px;
  }
}
